import { Button, useToast } from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DatePicker from 'react-datepicker';
import moment from 'moment'
const Attendance = () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    const toast = useToast();
    const id = "toast";

    const [CustomerList, setCustomerList] = useState('All');
    const [AttendanceDate, setAttendanceDate] = useState(new Date());
    const [FilteredRowData, setFilteredRowData] = useState([]);
  
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs, setColumnDefs] = useState([
      {
        headerName: "Card No",
        field: "cardNo",
      },
      {
        headerName: "Customer Name",
        field: "customerName",
      },
      {
        headerName: "Phone No",
        field: "contactNo1",
      },
      {
        headerName: "Status",
        field: "isPresent",
        cellRenderer:({data})=>(
          <span className={`${data.isPresent ? 'bg-green-600' : 'bg-red-600'} px-1.5 py-0.5 rounded-md text-white font-semibold`}>
            {data.isPresent ? 'Present' : 'Absent'}
          </span>
        )
      },
      {
        headerName: "Attendance Date",
        field: "date",
        cellRenderer: ({data}) => moment(data.date).format('DD-MMMM-YYYY')
      },
      {
        headerName: "Action",
        cellRenderer: ({data}) => !data.isPresent && (<Button
           size='xs' 
           colorScheme='green'
           onClick={async () => {

             let body = {
                cardNo : data?.cardNo,
                isPresent : true,
                checkedBy : "Admin",
                date: moment(data.date)?.format('YYYY-MM-DD')
              }

              console.log("Mark Present Body", body);

              const response = await axios.post(`${process.env.REACT_APP_AHMS_URL}/api/shrucken/markAttendance`,body);
              console.log("Attendance", response);
              GetCustomerAttendanceList(data.date);
           }}
           >Mark Present</Button>)
      },
    ]);
  
    const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
    

      const defaultColDef = useMemo(() => ({
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
        cellClass: "no-border",
        resizable:true
      }));


      useEffect(()=>{
        if(CustomerList === "Present"){
            setFilteredRowData(rowData?.filter((data)=> data.isPresent))
        } else if(CustomerList === "Absent"){
            setFilteredRowData(rowData?.filter((data)=> !data.isPresent))
        } else {
            setFilteredRowData(rowData)
        }
      },[CustomerList,rowData])

      useEffect(()=>{
        GetCustomerAttendanceList(new Date());
      },[]);

      const GetCustomerAttendanceList = async (AttDate) => {
        console.log("Requested Date",AttDate)
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_AHMS_URL}/api/shrucken/allCustomersAttendance?date=${moment(AttDate).format('YYYY-MM-DD')}`
              );
              console.log("Attendance Response",response);
              setRowData(response?.data);
              setFilteredRowData(response?.data)
        } catch (err) {
            console.error(err);
        }
      }

  return (
    <div>
        <div className="flex items-center justify-between  border-b border-gray-400 mb-4 pb-2 text-left">
          <h1 className="text-gray-700 text-xl font-bold">Customer Attendance</h1>
        </div>
  
        <div className='flex justify-between items-center mb-4'>
            <div className='space-x-2'>
                <Button size='sm' rounded='full' onClick={()=>{setCustomerList('All')}} variant={CustomerList === 'All' ? 'solid' : 'outline'} colorScheme='blue'>All</Button>
                <Button size='sm' rounded='full' onClick={()=>{setCustomerList('Present')}} variant={CustomerList === 'Present' ? 'solid' : 'outline'} colorScheme='blue'>Present</Button>
                <Button size='sm' rounded='full' onClick={()=>{setCustomerList('Absent')}} variant={CustomerList === 'Absent' ? 'solid' : 'outline'} colorScheme='blue'>Absent</Button>
            </div>
            <div>
                <DatePicker
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full px-2 py-1.5 outline-none"
                  selected={AttendanceDate}
                  onChange={(date) => {
                    setAttendanceDate(date);
                    GetCustomerAttendanceList(date);
                  }}
                  placeholderText="Attendance Date"
                  dateFormat="dd-MMM-yyyy"
                />
            </div>
        </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={FilteredRowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={20}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>

    </div>
  )
}

export default Attendance