import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faUserGroup,
  faUser,
  faPersonDress,
} from "@fortawesome/free-solid-svg-icons";
import { Avatar } from "@chakra-ui/react";
import axios from "axios";
import Attendance from "./Components/Attendance";

function Dashboard() {
  const userData = JSON.parse(localStorage.getItem("user"));

  // console.log("token",userData.token);

  const [CustomerStat, setCustomerStat] = useState([]);

  useEffect(() => {
    GetCustomerStat();
  }, []);

  const GetCustomerStat = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AHMS_URL}/api/shrucken/customerStats/${userData.phone}`
        // ,{
        //   headers: {
        //     authorization: userData.token
        //   }
    
        // }
      );
      console.log("Get Customer List Response", response.data);
      setCustomerStat(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="relative bg-indigo-300 p-4 sm:p-6 rounded-lg overflow-hidden mb-6">
        {/* Background illustration */}
        <div
          className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block"
          aria-hidden="true"
        >
          <svg
            width="319"
            height="198"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <path id="welcome-a" d="M64 0l64 128-64-20-64 20z" />
              <path id="welcome-e" d="M40 0l40 80-40-12.5L0 80z" />
              <path id="welcome-g" d="M40 0l40 80-40-12.5L0 80z" />
              <linearGradient
                x1="50%"
                y1="0%"
                x2="50%"
                y2="100%"
                id="welcome-b"
              >
                <stop stopColor="#A5B4FC" offset="0%" />
                <stop stopColor="#818CF8" offset="100%" />
              </linearGradient>
              <linearGradient
                x1="50%"
                y1="24.537%"
                x2="50%"
                y2="100%"
                id="welcome-c"
              >
                <stop stopColor="#4338CA" offset="0%" />
                <stop stopColor="#6366F1" stopOpacity="0" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
              <g transform="rotate(64 36.592 105.604)">
                <mask id="welcome-d" fill="#fff">
                  <use xlinkHref="#welcome-a" />
                </mask>
                <use fill="url(#welcome-b)" xlinkHref="#welcome-a" />
                <path
                  fill="url(#welcome-c)"
                  mask="url(#welcome-d)"
                  d="M64-24h80v152H64z"
                />
              </g>
              <g transform="rotate(-51 91.324 -105.372)">
                <mask id="welcome-f" fill="#fff">
                  <use xlinkHref="#welcome-e" />
                </mask>
                <use fill="url(#welcome-b)" xlinkHref="#welcome-e" />
                <path
                  fill="url(#welcome-c)"
                  mask="url(#welcome-f)"
                  d="M40.333-15.147h50v95h-50z"
                />
              </g>
              <g transform="rotate(44 61.546 392.623)">
                <mask id="welcome-h" fill="#fff">
                  <use xlinkHref="#welcome-g" />
                </mask>
                <use fill="url(#welcome-b)" xlinkHref="#welcome-g" />
                <path
                  fill="url(#welcome-c)"
                  mask="url(#welcome-h)"
                  d="M40.333-15.147h50v95h-50z"
                />
              </g>
            </g>
          </svg>
        </div>

        {/* Content */}
        <div className="relative">
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-1">
            Hello, {userData.name}! 👋
          </h1>
          <p>Welcome Back</p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-4 gap-6">
        <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36">
          <div className="flex flex-col justify-between">
            <p className="text-sm font-medium text-gray-500">Total Customers</p>

            <h6 className="text-3xl font-bold">
              {CustomerStat.TotalCustomers || 0}
            </h6>

            <p className="text-sm text-gray-50">-</p>
          </div>
          <div className="align-middle my-auto">
            <div className="bg-red-100 rounded-full ">
              <Avatar
                bg="green.100"
                size="lg"
                icon={
                  <FontAwesomeIcon
                    className=" text-green-600 text-3xl"
                    icon={faUsers}
                  />
                }
              />
            </div>
          </div>
        </div>

        <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36">
          <div className="flex flex-col justify-between">
            <p className="text-sm font-medium text-gray-500">Total Male</p>
            <h6 className="text-3xl font-bold">
              {CustomerStat.TotalMale || 0}
            </h6>
            <p className="text-sm text-gray-50">-</p>
          </div>
          <div className="align-middle my-auto">
            <div className="bg-orange-100 rounded-full">
              <Avatar
                bg="orange.100"
                size="lg"
                icon={
                  <FontAwesomeIcon
                    className=" text-orange-600 text-3xl"
                    icon={faUser}
                  />
                }
              />
            </div>
          </div>
        </div>

        <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36">
          <div className="flex flex-col justify-between">
            <p className="text-sm font-medium text-gray-500">Total Female</p>
            <h6 className="text-3xl font-bold">
              {CustomerStat.TotalFemale || 0}
            </h6>
            <p className="text-sm text-gray-50">-</p>
          </div>
          <div className="align-middle my-auto">
            <div className="bg-purple-100 rounded-full ">
              <Avatar
                bg="purple.100"
                size="lg"
                icon={
                  <FontAwesomeIcon
                    className=" text-purple-600 text-3xl"
                    icon={faPersonDress}
                  />
                }
              />
            </div>
          </div>
        </div>

        <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36">
          <div className="flex flex-col justify-between">
            <p className="text-sm font-medium text-gray-500">Couples</p>
            <h6 className="text-3xl font-bold">
              {CustomerStat.TotalCouples || 0}
            </h6>
            <p className="text-sm text-gray-50">-</p>
          </div>
          <div className="align-middle my-auto">
            <div className="bg-red-100 rounded-full">
              <Avatar
                bg="blue.100"
                size="lg"
                icon={
                  <FontAwesomeIcon
                    className=" text-blue-600 text-3xl"
                    icon={faUserGroup}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
      <Attendance/>
    </div>
  );
}

export default Dashboard;
